import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProjectDetails from "../../images/projects/Bloomfield-Enclave.yml"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template
} from "../../page-components/projects/template"

const BloomfieldEnclave = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query(
      $floorPlan: String = "/images/projects/bloomfield-enclave/04 FLOOR PLANS/"
    ) {
      logo: file(
        relativePath: { eq: "projects/bloomfield-enclave/BF ENCLAVE LOGO.png" }
      ) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: {
          eq: "projects/bloomfield-enclave/03 SITE DEVELOPMENT PLAN/BF Enclave SDP.png"
        }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "TownhouseUnits", num: "429" },
    { metric: "TypesofUnits", num: "2" },
    { metric: "TypicalLotArea", num: "36-49" },
    { metric: "TypicalFloorArea", num: "38" },
  ])
  const props: LocationTemplateProps = {
    title: "Bloomfield Enclave",
    logo,
    logoSz: 120,
    location: "Brgy. San Jose, Magalang, Pampanga",
    metrics,
    carouselImgs: [
      require("../../images/projects/bloomfield-enclave/CAROUSEL PHOTOS/01 BF ENCLAVE AERIAL 1.png"),
      require("../../images/projects/bloomfield-enclave/CAROUSEL PHOTOS/02 BF ENCLAVE GATE.jpg"),
      require("../../images/projects/bloomfield-enclave/CAROUSEL PHOTOS/03 BF ENCLAVE AMENITIES.jpg"),
      require("../../images/projects/bloomfield-enclave/CAROUSEL PHOTOS/04 BF ENCLAVE TOWNHOUSES.jpg"),
    ],
    siteDev: {
      img: siteDevImg,
      maxWidthImg: 400,
      // TODO: check this also for spacing
      txt: `Bloomfield Enclave is designed for easy wayfinding and effortless maneuvering. It’s amenities are located away from the townhouses to prioritize privacy and to give the development a clean and uniform look.

        Homeowners are able to enjoy its very own chapel, open-air clubhouse, swimming pool, and basketball court - making Bloomfield Enclave the ideal place to live.`,
    },
    amenities: {
      description:
        "Bloomfield Enclave offers endless possibilities with several amenities at your convenience. Fully equipped with a clubhouse, swimming pool, basketball court, and chapel, BF Enclave gives its homeowners the opportunity to create beautiful memories and a haven for easy living.",
      items: [
        {
          name: "Clubhouse",
          description:
            "The open-air Clubhouse is ready for anyone's use for events, gatherings, and other activities. It’s surrounded by other amenities which makes it easy to go from one activity to another.",
          img: require("../../images/projects/bloomfield-enclave/06 AMENITIES _ FEATURES/01 BF ENCLAVE CLUBHOUSE.png"),
        },
        {
          name: "Chapel",
          description:
            "Find inner peace at your convenience through the chapel.",
          img: require("../../images/projects/bloomfield-enclave/06 AMENITIES _ FEATURES/02 BF ENCLAVE CHAPEL.png"),
        },
        {
          name: "Swimming Pool",
          description: `Get your dose of vitamin D while enjoying fresh air by the swimming pool.

            The swimming pool is located in a more secluded area to ensure privacy and also cater to private events. `,
          img: require("../../images/projects/bloomfield-enclave/06 AMENITIES _ FEATURES/03 BF ENCLAVE SWIMMING POOL.png"),
        },
        {
          name: "Basketball Court",
          description:
            "Enjoy the outdoors while having fun with physical activities such as a fun game of basketball. ",
          img: require("../../images/projects/bloomfield-enclave/06 AMENITIES _ FEATURES/04 BF ENCLAVE BASKETBALL COURT.png"),
        },
      ],
    },
    floorPlans: {
      // TODO: check format of this too
      description:
        "Bloomfield Enclave offers two (2) types of units. (1) Townhouse A - stands at two-storeys with a typical lot area ranging from 36-49sq.m; and (2) Townhouse B - a two-storey unit equipped with a store space with a typical lot area of 36sq.m \n Our units give you the freedom and the power to create your home the way you want to. ** All Floor Plans below are for suggested layout only.",
      items: [
        [
          {
            name: "Townhouse A (End Unit)",
            fpImg: fpImg("BF ENCLAVE THA END WINDOW FP"),
            pImg: fpImg("BF ENCLAVE THA END WINDOW PERSP"),
          },
          {
            name: "Townhouse A (Inner Unit)",
            fpImg: fpImg("BF ENCLAVE THA INNER FP"),
            pImg: fpImg("BF ENCLAVE THA INNER"),
          },
          {
            name: "Townhouse B (Inner Unit)",
            fpImg: fpImg("BF ENCLAVE THB INNER FP"),
            pImg: fpImg("BF ENCLAVE THB INNER"),
          },
        ],
      ],
    },
    projDetails: {
      ...ProjectDetails,
      name: "Bloomfield Enclave",
      description:
        // TODO: check this
        "Located nearby the best-selling Bloomfield development is the new Bloomfield Enclave. With its close proximity to nearby establishments and facilities, BF Enclave boasts its affordable housing strategically located in Magalang, Pampanga. \n Bloomfield Enclave is a private and secure community designed to cater to all types of people, from young couples to retirees and OFW’s.",
    },
  }
  return <Template {...props} />
}

export default BloomfieldEnclave
